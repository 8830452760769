@import '../../../../../theme/colors';
@import '../../../../../theme/breakpoints';

.detailContent {
  font-size: 0.9rem;
  padding-bottom: 10px;
  
  li {
    line-height: 40px;
  }

  a {
    text-decoration: none;
    color: $colors-blue6;

    &:hover {
      text-decoration: underline;
    }
  }

  @include bp-min-md {
    font-size: 1.2rem;
    line-height: 27px;
    padding-bottom: 18px;
  }
  @include bp-min-lg {
    font-size: 1.4rem;
  }
}

.actionText {
  color: $colors-dark-gray2
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}