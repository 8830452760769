@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.segment {
  padding: 10px 2px;
  overflow: hidden;
  color: transparent;
  @include bp-min-md {
    color: $colors-white
  }
  &:hover {
    cursor: pointer;
  }
}

.hiddenText {
  color: transparent;
  text-decoration: none;
}