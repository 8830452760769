@import '../../theme/colors';
@import '../../theme/breakpoints';

#card {
  margin-top: 15px;
  font: inherit;
  line-height: 30px;
  font-size: 0.75rem;
  border-radius: 10px;
  padding: 10px 20px;
  padding-bottom: 30px;

  @include bp-min-lg {
    font-size: 0.88rem;
    padding: 20px 40px;
    padding-bottom: 40px;
  }
}

.accordionWrapper {
  iframe {
    aspect-ratio: 16 / 9;
    height: 70%;
    width: 70%;
    margin: 10px auto;
  }
}



a {
  color: $colors-blue1;
  text-underline-offset: 3.5px;
}

a:hover {
  text-decoration: none;
}

a:focus:active {
  text-decoration: none;
}

.icon {
  width: 28px;
  margin-right: 8px;
}
