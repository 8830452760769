@import '../../../../../theme/colors';
@import '../../../../../theme/breakpoints';

.card {
  background-color: $colors-white;
  padding: 25px 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include bp-min-md {
    flex-direction: row;
    gap:25px;
  }

  @include bp-min-md {
    gap:30px;
  }
}

.cardSection {
  display: flex;
  flex-direction: column;
}

.motion {
  @include bp-min-sm {
    width: 20%;
  }

  @include bp-min-lg {
    width: 45%;
  }
}

.title {
  font-weight: bold;
}