@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.footerSection {
  background-color: $colors-light-gray1;
  border-top-right-radius: 50% 20px;

  @include bp-min-md {
    border-top-right-radius: 70% 23%;
  }
  @include bp-min-lg {
    border-top-right-radius: 80% 40%;
  }

}

.footerTransition {
  position: absolute;
  width: 100%;
  transform: translateY(-20px);
}

.footerContentSection {
  color: $colors-dark-gray3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 60px 0; 
  
  @include bp-min-md {
    @include site-horizontal-padding;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
    padding-top: 100px;
  }
  @include bp-min-lg {
    padding-top: 140px;
  }

  @include bp-min-xl {
    justify-content: center;
    gap: 200px;
  }
}

.siteProviderInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  font-size: 12px;

  @include bp-min-md {
    align-items: flex-start;
    font-size: 18px;
  }

  img {
    width: 122px;
    height: auto;

    @include bp-min-md {
      width: 167px;
    }
  }

}

.copyrightLink {
  text-decoration: none;
  color: $colors-blue6;
  
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

.listHeading {
  font-size: 1.13rem;
  font-weight: bold;

  @include bp-min-lg {
    font-size: 1.4rem;
  }
}

.learnMoreSection {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap:10px;
  @include bp-min-md {
    padding: 15px 0 100px 0;
    text-align: start;
  }
}

.socialMediaSection {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 40px;

  @include bp-min-md {
    padding: 15px 0;
  }
}