@import '../../theme/typography';
@import '../../theme/breakpoints';
@import '../../theme/colors';

.filterConfigSection {
  margin: 80px 0;
  color: $colors-dark-gray3;

  svg {
    margin-top: 0;
    padding: 0;
  }

  ol {
    padding-inline-start: 0;
    margin-inline-start: 15px;
    margin-bottom: 25px;
  }

  li {
    padding: 15px 0 0 15px;
    line-height: 22px;
  }
}

.header {
  font-size: 2rem;
  margin: 12px 0;
}

.filtersTableSection {
  background-color: $colors-light-gray1;
  border-radius: 10px;
  padding-bottom: 20px;
}

.tableTools {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.filterButtons {
  display: flex;
  gap:20px;
}

.filtersTableTop {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}


.tableInfo {
  display: flex;
  justify-content: space-between;
}
