@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.sortHeading {
  margin-bottom: 20px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  border-bottom: 1px solid $colors-light-gray4;
  svg {
    margin: 0;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: underline !important;
}

.helperText {
  font-size: 18px;

  @include bp-min-lg {
    font-size: 1.25rem;
  }
}

.searchErrorLink {
  color: $colors-blue1;
  font-size: inherit;
}
