@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.headingContainer {
  display:flex;
  flex-direction: column;
  padding-bottom: 11px;
  border-bottom: 1px solid $colors-light-gray4;
  @include bp-min-sm {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

  }
}

.heading {
  padding-bottom: 11px;
}

.inputsContainer,
.inputsContainerEmail {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @include bp-min-lg {
    margin: 15px 0px;
    gap: 20px;
  }

  .input {
    display: flex;
    flex-direction: column;
  }
}
