@import '../../theme/colors';
@import '../../theme/breakpoints';

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 140px;
  img {
    max-height: 70px;
    align-self: flex-end;
  }
  @include bp-min-md {
    flex-direction: row;
    height: 100px;
  }

  @include bp-min-lg {
    img {
      max-height: 100px;
    }
  }
}


.billTotal {
  font-weight: 300;
}

.headingLink {
  border-left: $colors-black solid 2px;
  padding-left: 10px;
  text-decoration: none;
}

.descriptionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin: 15px 0 25px 0;
  color: $colors-white;
  background-color: $colors-dark-gray2;
  font-size: 18px;
  padding: 20px 34px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.logoutButton {
  font-family: inherit;
  margin: 0 15px;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: $color-yellow7;

  @include bp-min-md {
    font-size: 1.1rem;
  }

  @include bp-min-lg {
    font-size: 1.4rem;
  }

  &:hover {
    background-color: $colors-black;
    color: $colors-white;
    cursor: pointer;
  }

  &:focus {
    background-color: $colors-black;
    color: $colors-white;
    outline: none;
  }

}
