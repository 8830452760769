@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.tabHeading {
  font-size: 1.5rem;

  @include bp-min-md {
    margin: 52px 0px 32px 0;
  }
}

.tabPanel {
  background-color: $colors-light-gray7;
  border-radius: 10px;
  border-top-left-radius: 0px;
  min-height: 700px;
}

#tab {
  background-color: $colors-light-gray7;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  min-width: 200px;
  margin-right: 5px;
  min-height: 55px;
  font-family: 'Lato';
  text-transform: none;
  font-size: 1.2rem;
}

.billsTab {
  min-height: 500px;
}

#timelineHeading {
  font-weight: 600;
  margin-left: 5px;
  margin-top: 20px;
  font-size: 1rem;

  @include bp-min-lg {
    font-size: 1.3rem;
  }
}
