@import '../../theme/colors';
@import '../../theme/breakpoints';

.mapSection {
  margin: 20px 10px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.visualizationsTab {
  padding: 10px 5px;

  @include bp-min-lg {
    padding: 20px 0;
  }

  .heading {
    padding: 0px;
    padding-bottom: 10px;
    font-size: 0.95rem;

    @include bp-min-base {
      font-size: 1.1rem;
    }
  }

  p {
    font-weight: 300;
    font-size: 0.85rem;
    letter-spacing: 1px;
    padding-bottom: 10px;

    @include bp-min-base {
      padding-bottom: 0px;
      font-size: 1.1rem;
    }
  }

  .billCountLabel {
    font-weight: 500;
    letter-spacing: initial;
    font-size: 0.65rem;
    float: right;
    min-height: 30px;
    min-width: 200px;
    text-align: right;
    margin-bottom: -30px;
    margin-top: 10px;
    margin-right: 20px;

    @include bp-min-base {
      font-size: 0.85rem;
    }
  }
}

.toolTip {
  position: relative;
  background-color: $colors-white !important;
  color: $colors-dark-gray3 !important;
  opacity: 1 !important;
}