@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp-min-lg {
    flex-direction: row;
  }
}

.formSection {
  background-color: $colors-light-gray5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  width: 100%;
  justify-content: center;

  @include bp-min-lg {
    padding: 30px;
    min-width: 350px;
  }

  form {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }

  img {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .forgotPassword {
    font-size: 0.9rem;
    margin-bottom: 15px;
    color: $colors-dark-gray2;

    @include bp-min-lg {
      margin-bottom: 0px;
      margin-left: 6px;
    }
  }

  .buttonLinkContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    gap: 20px;
  }

  .createAcctContainer {
    padding: 25px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }

  .loginButton {
    width: 100%;
    background-color: $colors-dark-gray2;

    &:hover {
      background-color: $colors-dark-gray2;
    }
  }
}
