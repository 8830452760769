@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.card {
  margin-top: 20px;
  font: inherit;
  text-align: left;
  line-height: 30px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  background-color: $colors-white;
  border-radius: 10px;

  @include bp-min-md {
    display: grid;
    margin-top: 45px;
  }

  @include bp-min-lg {
    font-size: 0.88rem;
  }
}

.cardInfo {
  display: flex;
  flex-direction: column;

  @include bp-min-md {
    display: grid;
    grid-template-columns: 0.75fr 1fr 1fr 0.8fr 0.75fr;
  }

  @include bp-min-lg {
    display: grid;
    grid-template-columns: 0.75fr 1fr 1fr 0.8fr 0.75fr;
  }
}

.billSummary {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include bp-min-md {
    flex-grow: 1;
    max-width: 40%;
  }
}

.billDesignation {
  color: $colors-green1;
  font-size: 1.3rem;
  font-weight: bold;
}

.billDescription {
  padding: 8px 0;
  line-height: 18px;
}

.billOuterLeft,
.billOuterRight,
.billInner {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px dashed $colors-green2;
  border-bottom: 1px dashed $colors-green2;

  @include bp-min-md {
    padding: 20px 15px;
    border-left: 1px dashed $colors-green2;
    border-right: 1px dashed $colors-green2;
    border-top: 0px dashed $colors-green2;
    border-bottom: 0px dashed $colors-green2;
  }

  @include bp-min-lg {
    padding: 20px 27px;
  }
}

.overflow {
  overflow-x: auto;
  overflow-y: hidden;
}

.billOuterLeft {
  border-left: none;
  border-top: 0px dashed $colors-green2;
}

.billOuterRight {
  border-right: none;
  border-bottom: 0px dashed $colors-green2;
}

.sectionTitle {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 20px;

  @include bp-min-lg {
    font-size: 0.95rem;
  }
}

.sponsors {
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.billIconContainer {
  line-height: 18px;
  max-width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 25px;

  @include bp-min-md {
    gap: 10px;
  }

  @include bp-min-lg {
    gap: 25px;
  }
}

.billIcon {
  width: 30px;

  @include bp-min-lg {
    width: 50px;
  }
}

.policyText {
  padding-right: 1rem;
}

.billTrack {
  font: inherit;
  line-height: 17px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: $colors-white;
  background: linear-gradient(#61a739 30%, #076324);
  border-radius: 0 0 10px 10px;
  border: 0px;
  cursor: pointer;

  &:hover {
    color: $colors-black;
    background: $colors-green6;
  }

  &:focus {
    color: $colors-black;
    background: $colors-green6;
    outline: none;
  }

  @include bp-min-md {
    text-align: center;
    width: 74px;
    flex-direction: column;
    border-radius: 0 10px 10px 0;
  }
}

.companionBillLink {
  font-size: 0.75rem;

  @include bp-min-lg {
    font-size: 1rem;
  }
}
