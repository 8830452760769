@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.filterContainer {
  width: 100%;
}

.filterHeader {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 1.3rem;
  border-bottom: 1px solid $colors-light-gray4 ;
  svg {
    margin-top: 0;
  }
}

.titleWrapper {
  display: flex;
  align-items: baseline;
    svg {
    margin-top: 0;
  }
}

.icon {
  margin-top: 0;
}

.resetButton {
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  font-family: 'Lato';
  color: $colors-blue2;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    outline: none;
  }
}

.filterForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttonWrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  @include bp-min-lg {
    justify-content: center;
  }
}


.input {

  svg {
    margin-top: 0;
  }
}
