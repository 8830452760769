@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.itemContainer {
  display: flex;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
}

.itemPercent {
  background-color: $colors-light-gray8;
  font-weight: bold;
  padding: 8px;
}

.itemText {
  background-color: $colors-white;
  font-weight: 300;
  color: $colors-dark-gray3;
  padding: 8px;
  font-size: .9rem;
  white-space: nowrap;

  @include bp-min-lg {
    font-size: 1rem;
  }
}