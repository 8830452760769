@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.socialLinkIcons {
  display: flex;
  gap: 15px;
  svg {
    margin: 0;
    padding: 0;
  }
}

.iconLink {
  border: 1px solid transparent;
  text-align: center;
  width:27px;
  padding: 6px;
  &:hover {
    border: 1px solid $colors-blue6;
    border-radius: 50%;
  }
  &:focus {
    outline: none;
    border: 1px solid $colors-blue6;
    border-radius: 50%;
  }
}

.icon {
  font-size: 27px;
  color: $colors-blue6;
}