@import './theme/typography';
@import './theme/breakpoints';
@import './theme/colors';
@import 'react-toastify/dist/ReactToastify.css';

.skipNavOption {
  position: absolute;
  border-radius: 5px;
  padding: 5px;
  font-size: 1.2rem;
  background-color: $colors-light-gray1;
  color: $colors-blue6;
  text-decoration: none;
  z-index: 2;
  margin-left: -400px;
  &:focus {
    margin: 10px;
  }
}

.mainContent {
  margin: 0 auto;
  margin-top: -5px;
  @include site-horizontal-padding;

  .innerContent {
    max-width: $site-max-width;
    margin: 0 auto;
    padding-bottom: 100px;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0px;
}

th,
td {
  border: 1px solid rgba($colors-dark-gray2, 0.5);
  padding: 7px;
}

th {
  padding: 10px;
  font-weight: 400;
  background-color: rgba($colors-dark-gray2, 0.15);
}

.toast {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  svg {
    margin: 0;
  }
}
