@import '../../theme/colors';
@import '../../theme/breakpoints';

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
}

.trackerContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include bp-min-md {
    gap: 22px;
  }
}
