@import '../../theme/colors';
@import '../../theme/typography';
@import '../../theme/breakpoints';

.buttonMuiOverride {
  &:hover {
    .arrow {
      visibility: visible;
    }
  }

  &:focus {
    .arrow {
      visibility: visible;
    }
  }
}

.textIconContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 25px;

    @include bp-min-md {
      width: initial;
    }
  }
}

.arrow {
  visibility: hidden;
  position: absolute;
  color: $colors-white;
  background-color: $colors-black;
  padding: 0;
  padding: 4px 5.5px;
  border-radius: 50%;
  border: 1.5px solid $colors-white;
  font-size: inherit;
  right: -12px;
  top: 42.5%;
}
