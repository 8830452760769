@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp-min-lg {
    flex-direction: row;
  }
}

.headingSection {
  padding: 35px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include bp-min-lg {
    padding: 50px 65px 50px 30px;
  }

  h2 {
    color: $colors-green9;
    font-weight: 300;
    -webkit-text-stroke: 1px $colors-green9;
    font-size: 2rem;
    margin-bottom: 30px;
    letter-spacing: 2.65px;
    margin-top: -18px;
    text-align: center;
    padding: 0px;

    @include bp-min-lg {
      font-size: 2.4rem;
      padding: initial;
      text-align: left;
    }
  }

  p {
    font-size: 0.95rem;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;

    @include bp-min-lg {
      text-align: left;
      max-width: 250px;
      line-height: 28px;
      font-size: 1.1rem;
    }
  }
}

.form {
  display: flex;
  gap: 15px;
  flex-direction: column;
  background-color: $colors-light-gray5;
  padding: 20px 15px;
  border-radius: 5px;

  @include bp-min-lg {
    min-width: 350px;
    padding: 30px;
  }

  p {
    margin: 1rem 0;
    letter-spacing: 0;
    line-height: 25px;
  }
}

.inputContainer {
  width: 90%;
  margin: 1rem 0;
}

.inputNew {
  margin-bottom: 1.5rem;
}

.inputConfirm {
  margin-top: 1.5rem;
}

.buttonContainer {
  margin: 1rem 0 1.5rem 0;
}
