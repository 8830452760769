.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalTitle {
  padding: 0;
}

.modalDescription {
  max-width: 420px;
}

.modalBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;

  button {
    margin: 0 0.5rem;
  }
}