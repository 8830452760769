@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp-min-lg {
    flex-direction: row;
  }
}

.headingSection {
  padding: 35px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include bp-min-lg {
    padding: 50px 65px 50px 30px;
  }

  h2 {
    color: $colors-green9;
    font-weight: 300;
    -webkit-text-stroke: 1px $colors-green9;
    font-size: 2rem;
    margin-bottom: 30px;
    letter-spacing: 2.65px;
    margin-top: -18px;
    text-align: center;
    padding: 0px;

    @include bp-min-lg {
      font-size: 2.4rem;
      padding: initial;
      text-align: left;
    }
  }

  p {
    font-size: 0.95rem;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;

    @include bp-min-lg {
      text-align: left;
      max-width: 250px;
      line-height: 28px;
      font-size: 1.1rem;
    }
  }
}

.formSection {
  background-color: $colors-light-gray5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  justify-content: center;

  @include bp-min-lg {
    padding: 30px;
    min-width: 350px;
  }

  form {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }

  .forgotPassword {
    font-size: 0.9rem;
    margin-bottom: 15px;

    @include bp-min-lg {
      margin-bottom: 0px;
      margin-left: 6px;
    }
  }

  .buttonLinkContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
    border-bottom: solid 1.5px $colors-light-gray4;

    @include bp-min-lg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .createAcctContainer {
    padding: 25px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }
}
