@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.visualizationsTab {
  min-height: 700px;
  padding: 7px;
}

.visualizationsTab {
  padding: 20px 40px;
}

.tabPanel {
  background-color: $colors-light-gray7;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

.policyCategoryMapSection {
  text-align: center;
  margin: 20px 10px;
}
