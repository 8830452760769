@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.clearFieldButton {
  display: none;
  border: none;
  background: transparent;
  color: $colors-blue2;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    outline: none;
  }
  svg {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
}

.isVisible {
  display: unset;
}