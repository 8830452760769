@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.mainContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  gap: 20px;

  button {
      margin: 0;
  }

  @include bp-min-base {
    align-items: flex-start;
  }
}

.subHeader {
  @include bp-min-lg {
    line-height: 15px;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }
}

.pageTitleContainer {
  display: flex;
  align-items: center;

  div {
    border: none;
    width: 100%;
  }
}