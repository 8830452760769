@import '../../theme/colors';
@import '../../theme/breakpoints';


.tabPanel {
  background-color: $colors-light-gray7;
  border-radius: 10px;
  border-top-left-radius: 0px;

  svg {
    margin-top: 0;
    padding: 0;
  }
}

#tab {
  background-color: $colors-light-gray7;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  min-width: 200px;
  margin-right: 5px;
  min-height: 55px;
  font-family: 'Lato';
  text-transform: none;
  font-size: 1.2rem;
}
