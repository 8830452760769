@import '../../theme/colors';
@import '../../theme/breakpoints';

.iconContainer,
.iconOpen {
  margin-right: 12px;
  margin-top: -2px;

  @include bp-min-lg {
    margin-top: -3px;
  }

  img {
    width: 20px;
  }
}