@import '../../theme/colors';

.sponsorNameBillDetails {
  font-size: 1rem;
  font-weight: bold;
  display: flex;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $colors-blue6;
  }
}
