@import '../../theme/colors';
@import '../../theme/breakpoints';

.mainNavContainer {
  background: linear-gradient( #076324 10%, #61a739);
  position: relative;
  z-index: 1;
  height: 60px;

  @include bp-min-lg {
    height: 87px;
    @include site-horizontal-padding;
  }
}

.innerContent {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  max-width: $site-max-width;
}

.logosContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}


.navLinksContainer {
  color: $colors-white;
  font-size: 1.2rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.firstLayerContainer {
  display: flex;
  flex-direction: column;
}

.upperNavLink {
  li {
    &:focus-visible {
      outline: none;
      border: none;
    }
  }
  img {
    margin-left: 7px;
    width: 15px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.nav a {
  display: block;
  color: $colors-white;
  text-decoration: none !important;
  padding: 14px 0px;
  padding-left: 15px;
  font-family: 'Lato';
  position: relative;
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

.nav {
  vertical-align: top;
  display: inline-block;
  border-radius: 6px;
}

.nav li {
  position: relative;
}
.nav > li {
  float: left;
  margin-right: 1px;
}
.nav > li > a {
  padding: 0px;
  height: 87px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 70px;

  @include bp-min-xl {
    width: initial;
  }
}
.nav li:hover > a,
.nav li:active > a {
  filter: brightness(0) saturate(100%) invert(75%) sepia(59%) saturate(392%) hue-rotate(343deg) brightness(102%)
    contrast(101%);
  color: $colors-yellow1;
}

.nav li:focus-visible > a {
  filter: brightness(0) saturate(100%) invert(75%) sepia(59%) saturate(392%) hue-rotate(343deg) brightness(102%)
    contrast(101%);
  color: $colors-yellow1;
}

.nav ul {
  position: absolute;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;
  min-width: 88.5%;
  left: -99999em;
  margin-left: 70px;

  li {
    a {
      font-size: 1rem;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background-color: $colors-yellow1;

      a {
        color: $colors-dark-gray1;
        font-weight: bold;
        filter: none;
      }
    }
  }
}
.nav > li:hover > ul,
.nav > li:focus-within > ul {
  left: auto;
  visibility: visible;
}

.nav > li li ul {
  background-color: $colors-yellow4;
  margin-left: 0px;
  margin-top: 1px;
  border: none;
  width: 70%;

  li {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;

    a {
      color: $colors-black;
      font-weight: 700;
      font-size: 0.9rem;
    }
  }
}

.nav > li li:hover > ul,
.nav > li li:focus-within > ul {
  left: 100%;
  top: -1px;
}
