@import '../../theme/typography';
@import '../../theme/breakpoints';
@import '../../theme/colors';

.policyGrid {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(5, auto);
  gap: 77px;

  @include bp-min-md {
    padding: 46px;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(3, auto);
  }

  @include bp-min-lg {
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
  }
}

.policyCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap:11px;
}

.icon {
  width: 50px;
}

.text {
  display: block;
  width: 133px;
}
