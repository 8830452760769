@import '../../theme/colors';
@import '../../theme/breakpoints';

.csvSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;  
}

.csvSectionTitle {
  font-size: 18px;
  padding: 0;
  white-space: nowrap;
}

.csvSectionSelectContainer {
  width: 320px;
}
