@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.content {
  padding-top: 50px;
  padding-bottom: 25px;
}

.crumbLink {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.75px;
  font-size: 0.85rem;
  line-height: 45px;
}

.separator {
  font-family: 'Lato';
  margin-top: -4.25px;
  color: $colors-dark-gray2;
}
