@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import './colors';
@import './breakpoints.scss';

body {
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
  color: $colors-dark-gray1;
}

header {
  font-size: 1.13rem;
  font-weight: 400;
}

@mixin heading-1 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;

  @include bp-min-md {
    font-size: 1.6rem;
  }

  @include bp-min-lg {
    font-size: 1.875rem;
  }
}

@mixin heading-2 {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;

  @include bp-min-md {
    font-size: 1.25rem;
    line-height: 30px;
  }

  @include bp-min-xl {
    font-size: 1.25rem;
  }
}

@mixin heading-3 {
  font-size: 1.62rem;
  font-weight: 600;
}

@mixin heading-4 {
  font-size: 1.44rem;
  font-weight: 400;
  line-height: 38px;
  color: $colors-dark-gray1;
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}
