@import '../../theme/typography';
@import '../../theme/breakpoints';
@import '../../theme/colors';

.aboutPageContent {
  font-size: 18px;

  @include bp-min-md {
    font-size: 22px;
    line-height: 30px;
  }

  @include bp-min-lg {
    line-height: 33px;
  }
}

.pageHeadingSection {
  display: flex;
  flex-direction: column;

  @include bp-min-md {
    flex-direction: row;
  }
}

.introSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;
}

.aelDescriptionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include bp-min-md {
    flex-direction: row;
  }

  img {
    width: 210px;
    @include bp-min-md {
      padding-left: 30px;
    }
  }
}

.descriptionText {
  @include bp-min-md {
    padding-right: 26px;
    border-right: 1px solid $colors-dark-gray2;
  }
}

.outroSection {
  margin: 15px 0;
}
