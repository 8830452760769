@import '../../theme/colors';
@import '../../theme/breakpoints';
@import '../../theme/typography';

.stackedBarSection {
  margin: 20px 10px;
  padding-bottom: 50px;
}

.stackedBar{
  display: flex;
  width: 100%;
  margin: 40px 0px;
}

.centerHeading {
  text-align: center;
}

.heading {
  padding: 0px;
  padding-bottom: 10px;
  font-size: 0.95rem;

  @include bp-min-base {
    font-size: 1.1rem;
  }
}

.description {
  font-weight: 300;
  font-size: 0.85rem;
  letter-spacing: 1px;
  padding-bottom: 10px;

  @include bp-min-base {
    padding-bottom: 0px;
    font-size: 1.1rem;
  }
}
