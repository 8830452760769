@import '../../theme/colors';
@import '../../theme/breakpoints';

.mainContainer {
  overflow: hidden;
  max-width: 100%;
  height: 250px;
  position: relative;
}

.innerContainer {
  max-width: $site-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px;
  text-align: center;
  margin-top: -110px;

  @include bp-min-lg {
    margin-top: -80px;
    text-align: left;
    padding: 80px;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0px 70px;
    border-right: none;
    margin-top: -20px;

    @include bp-min-lg {
      margin-left: -50px;
      margin-top: none;
      border-right: solid 1px white;
    }

    img {
      width: 275px;

      @include bp-min-base {
        width: 320px;
        margin-top: -10px;
      }
    }
  }

  h2 {
    font-size: 0.85rem;
    font-weight: 300;
    -webkit-text-stroke: 0.6px white;
    line-height: 20px;
    padding: 10px 35px;
    margin: 0 auto;

    @include bp-min-base {
      font-size: 1.05rem;
      line-height: 26px;
    }

    @include bp-min-lg {
      margin-top: -17px;
      padding: 10px 0px;
      font-size: 1.06rem;
      margin-left: 80px;
    }
  }
}

.header {
  background: linear-gradient(#61a739 20%, #076324);
  overflow: hidden;
  height: 300px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  @include bp-min-lg {
    height: 250px;
  }
}

.aelHeaderCurve {
  width: 100%;
  position: relative;
  margin-top: -135px;

  @include bp-min-base {
    margin-top: -150px;
  }

  @include bp-min-lg {
    margin-top: -165px;
  }
}

.roundedContainer {
  position: absolute;
  width: 100%;
  top: 180px;

  @include bp-min-lg {
    top: 160px;
  }
}

.svgBanner {
  transform: rotate(181deg);
  width: calc(100% + 1px);
  height: 150px;
  padding: 0px;

  @include bp-min-lg {
    transform: rotate(180.5deg);
  }
}
