@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.billChangesCard {
  min-height: 400px;
}

.cardTitleContainer {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.cardTitleText {
  color: $colors-dark-gray3;
  font-size: 20px;
  padding-bottom: 0;
}

.cardSubtitleText {
  color: $colors-dark-gray3;
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 0;
  padding-right: 2rem;
}

.basicLine {
  margin: 1rem 0;
  height: 1px;
  width: 100%;
  background-color: $colors-light-gray4;
}

.selectContainer {
  max-width: calc($site-max-width / 2);
  margin-bottom: 1rem;
  scroll-margin: 100px;
}

.noDataText {
  color: $colors-dark-gray3;
  font-size: 1rem;
}

.billContainer {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .billLinks {
    text-align: left;
    text-decoration: none;
    font-size: 1rem;
    color: $colors-blue6;
    cursor: pointer;
    background-color: transparent;
    border: none;

    &:hover {
      text-decoration: underline;
    }
    @include bp-min-md {
      font-size: 1.2rem;
    }
    @include bp-min-lg {
      font-size: 1.4rem;
    }
  }
}
