@import '../../theme/colors';
@import '../../theme/breakpoints';

.pageIntro {
  display: flex;
  flex-direction: column;
}

.pageDescription {
  font-weight: 300;
  font-size: 0.85rem;
  letter-spacing: 1px;
  padding-bottom: 10px;

  @include bp-min-base {
    padding-bottom: 0px;
    font-size: 1.1rem;
  }
}

.tabHeading {
  font-size: 1.5rem;

  @include bp-min-md {
    margin: 52px 0px 32px 0;
  }
}
