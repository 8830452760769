@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.tabPanel {
  background-color: $colors-light-gray7;
  border-radius: 10px;
}

.policyCategoryMapSection {
  text-align: center;
  margin: 20px 10px;
}

.heading {
  padding: 0px;
  padding-bottom: 10px;
  font-size: 0.95rem;

  @include bp-min-base {
    font-size: 1.1rem;
  }
}

.description {
  font-weight: 300;
  font-size: 0.85rem;
  letter-spacing: 1px;
  padding-bottom: 10px;

  @include bp-min-base {
    padding-bottom: 0px;
    font-size: 1.1rem;
  }
}

.tabHeading {
  font-size: 1.5rem;

  @include bp-min-md {
    margin: 32px 0px 32px 0;
  }
}

.billCountLabel {
  font-weight: 500;
  letter-spacing: initial;
  font-size: 0.65rem;
  float: right;
  min-height: 30px;
  min-width: 200px;
  text-align: right;
  margin-bottom: -30px;
  margin-top: 10px;
  margin-right: 20px;

  @include bp-min-base {
    font-size: 0.85rem;
  }
}

.toolTip {
  background-color: $colors-white !important;
  color: $colors-dark-gray3 !important;
  opacity: 1 !important;
}

#tab {
  background-color: $colors-light-gray7;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  min-width: 200px;
  margin-right: 5px;
  min-height: 55px;
  font-family: 'Lato';
  text-transform: none;
  font-size: 1.2rem;
}
