@import '../../../theme/typography';
@import '../../../theme/breakpoints';
@import '../../../theme/colors';

.heading {
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid;
}

.formContainer {
  height: 400px;
  padding: 15px;
  form {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}


.buttonContainer {
  padding: 30px 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}