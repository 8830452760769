@import 'src/theme/colors';
@import 'src/theme/breakpoints';

.summary {
  width: 100%;
}

.summaryContent {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: bold;
  font-size: 0.95rem;

  @include bp-min-md {
    font-size: 1.2rem;
  }

  @include bp-min-lg {
    font-size: 1.6rem;
  }
}

.detailContent {
  font-size: 0.8rem;
  line-height: 18px;
  padding: 0px;

  a {
    color: $colors-blue1;
    text-underline-offset: 3.5px;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus:active {
    text-decoration: underline overline;
  }

  @include bp-min-md {
    font-size: 1rem;
    line-height: 27px;
  }
  @include bp-min-lg {
    font-size: 1.25rem;
  }
}
