@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.faqSection {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  max-width: $site-max-width;

  @include bp-min-md {
    align-items: center;
    padding: 60px;
  }
}
