@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.editOption {
  background-color: transparent;
  color: $colors-blue6;
  border: none;
  font-family: inherit;
  font-size: 20px;
  &:hover, &:focus {
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  }
}