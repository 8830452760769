@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.accountPrefContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  @include bp-min-md {
    flex-direction: row;
  }
}

.accountCard {
  background: rgba($colors-blue7, .1) !important;
  height: fit-content;
}