@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.preferenceSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headingContainer {
  display:flex;
  flex-direction: column;
  padding-bottom: 11px;
  border-bottom: 1px solid $colors-light-gray4;
  @include bp-min-sm {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

  }
}

.optionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading {
  padding-bottom: 11px;
}

.dropdownContainer {
  width: 50%;
}

.notificationOptions {
  display: grid;
  grid-template-rows: repeat(2, 50%);
  grid-template-columns: repeat(2, 50%);
}

.label{
  font-family: 'Lato', sans-serif;
}

.checkboxLabel {
  margin: 5px 0;
  svg {
    margin: 0;
  }
}