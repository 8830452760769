@import '../../theme/colors';
@import '../../theme/breakpoints';

.cardContainer {
  background-color: $colors-light-gray5;
  border-radius: 10px;
  width: 100%;
  padding: 5px 40px;
  margin: 20px 0;

  @include bp-min-md {
    width: 90%;
  }
}
