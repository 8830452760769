@import '../../../theme/colors';
@import '../../../theme/breakpoints';
@import '../../../theme/typography';

.learnMoreLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.learnMoreLink {
  color: $colors-blue1;
  font-size: 1.13rem;
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }
}