@import '../../../theme/colors';

.navLinksContainer {
  position: relative;
  z-index: 2;
  color: $colors-white;
  font-family: 'Lato';
}

#listItem:hover {
  color: $colors-yellow1;
  background-color: rgba(0, 0, 0, 0.85);
}

.chevronIcon {
  background-color: transparent;
  width: 20px;
  filter: brightness(0) saturate(100%) invert(99%) sepia(9%) saturate(7425%) hue-rotate(182deg) brightness(127%)
    contrast(93%);
}

.chevronIcon:hover,
#listItem:hover > .chevronIcon {
  filter: brightness(0) saturate(100%) invert(96%) sepia(26%) saturate(6857%) hue-rotate(307deg) brightness(103%)
    contrast(108%);
}

.hoverYellow:hover {
  background-color: $colors-yellow4;
}