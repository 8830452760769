@import '../../../theme/breakpoints';
@import '../../../theme/colors';

.mainContainer {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;

  @include bp-min-lg {
    text-align: left;
    align-items: center;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 25px auto 0 auto;

  @include bp-min-sm {
    margin: 10px auto 0 auto;
  }

  @include bp-min-lg {
    margin: 0 auto;
  }
}

.textStateContainer {
  padding: 0;
  display: flex;
  align-items: center;
  padding-right: 50px;

  @include bp-min-lg {
    border-right: solid 1px $colors-dark-gray2;
  }

  h1 {
    margin-top: 15px;
    color: $colors-green1;
    -webkit-text-stroke: 1px $colors-green1;

    @include bp-min-lg {
      margin-top: -15px;
    }

    span {
      color: $colors-dark-gray2;
      -webkit-text-stroke: 0px;
      font-weight: 300;
    }
  }
}

.subtitle {
  font-size: 0.85rem;
  @include bp-min-base {
    font-size: inherit;
  }
}

#icon {
  margin: 0;
  padding: 0;
  stroke-width: 0;
  stroke: $colors-green10;
  fill: $colors-green10;
  width: 90px;
  padding-right: 30px;
}

#billButton {
  font-size: 0.85rem;
  padding: 20px 90px;
  margin: 0 auto;
  margin-top: 50px;

  @include bp-min-base {
    padding: 20px 125px;
  }

  @include bp-min-lg {
    margin-top: 0;
    font-size: 1.1rem;
    padding: 25px 70px;
    margin-left: 30px;
  }
}
