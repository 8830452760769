@import '../../../theme/colors';
@import '../../../theme/breakpoints';

.gradientTitle {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
  font-size: 0.75rem;
  margin-top: -8px;

  @include bp-min-lg {
    font-size: 0.85rem;
  }
}

.gradientScale,
.gradientLabels {
  width: 65%;
  height: 15px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 0.6rem;
  justify-content: flex-end;
  text-align: right;
  @include bp-min-lg {
    width: 50%;
    height: 14px;
    font-size: 0.72rem;
  }
}

.gradientScale {
  background: linear-gradient(to right, #c4e1b0, #3c691b);
}

.gradientLabels {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 78%;
  padding-top: 5px;

  @include bp-min-lg {
    width: 60%;
  }
}

.labelContainer {
  display: flex;
  justify-content: center;
}
