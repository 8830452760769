@import '../../theme/colors';
@import '../../theme/breakpoints';

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include bp-min-md {
    gap: 22px;
  }
}

.paginationContainer {
  padding: 12px 0;
  border-radius: 10px;
  background-color: $colors-white;
}