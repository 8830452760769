@import '../../theme/colors';
@import '../../theme/breakpoints';

.billsTab,
.visualizationsTab {
  min-height: 700px;
  padding: 7px;
}

.visualizationsTab {
  padding: 20px 40px;
}

.billsTab {
  background-image: url('../../assets/logos/transparent-cnee-logo.svg');
  background-repeat: no-repeat;
  background-size: 70%;
  background-position-x: 50%;
  background-position-y: 60%;
}

.tabPanel {
  background-color: $colors-light-gray7;
  border-radius: 10px;
  border-top-left-radius: 0px;
}

#tab {
  background-color: $colors-light-gray7;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  min-width: 200px;
  margin-right: 5px;
  min-height: 55px;
  font-family: 'Lato';
  text-transform: none;
  font-size: 1.2rem;
}

.visualizationBordered {
  border-bottom: solid 1px $colors-light-gray4;
}

.toolTip {
  font: inherit !important;
  background-color: $colors-white !important;
  color: $colors-dark-gray3 !important;
  opacity: 1 !important;
}

.bold {
  font-weight: bold;
}
