@import '../../../../theme/colors';
@import '../../../../theme/breakpoints';

.modalContent {
  width: 100%;
}
.headingSection {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: -20px;
  align-items: flex-end;

  @include bp-min-lg {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    min-width: 650px;
    margin-bottom: 30px;
  }

  .registerTitle {
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0 auto;
    padding: 10px;

    @include bp-min-lg {
      margin: initial;
      padding: initial;
      font-size: 1.4rem;
    }
  }

  .aelTitle {
    width: 120px;
    height: 20px;
    flex-shrink: 0;
    position: relative;
    float: right;

    @include bp-min-lg {
      width: 250px;
      height: 35px;
    }
  }
}

.formSection {
  margin-top: 20px;
  background-color: $colors-light-gray5;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  overflow-y: scroll;
}

.formSection::-webkit-scrollbar {
  width: 5px;
  margin-top: 15px;
}

.formSection::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 2px;
}

.formSection::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.283);
  width: 2px;
  border-radius: 30px;
}

.inputsContainer,
.inputsContainerEmail {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 10px;

  @include bp-min-lg {
    margin: 15px 0px;
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }

  .input {
    display: flex;
    flex-direction: column;
  }
}

.inputsContainerEmail {
  grid-template-columns: 1fr;
}

.buttonLinkContainer {
  margin: 20px 0px;

  @include bp-min-lg {
    margin: 30px 0px 20px 0px;
  }
}
