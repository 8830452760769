@import '../../theme/colors';
@import '../../theme/breakpoints';

.card {
  font: inherit;
  text-align: left;
  line-height: 30px;
  font-size: 0.88rem;
  display: flex;
  flex-direction: column;
  background-color: $colors-white;
  border-radius: 10px;

  @include bp-min-md {
    flex-direction: row;
  }

  &:hover {
    transform: scale(1.015);
  }
}

.cardInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
  @include bp-min-md {
    flex-direction: row;
  }
}

.billSummary {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include bp-min-md {
    width: 80%;
  }
}

.billDesignation {
  color: $colors-green1;
  font-size: 1.3rem;
  font-weight: bold;
}

.billDescription {
  min-height: 85px;
  overflow: hidden;
  padding: 8px 0;
  line-height: 18px;
  max-width: 95%;
}

.billDate {
  font-style: italic;
}

.billSponsors {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 10px;
  border-top: 1px dashed $colors-green2;
  border-bottom: 1px dashed $colors-green2;
  overflow: auto;

  @include bp-min-md {
    width: 150px;
    border-left: 1px dashed $colors-green2;
    border-right: 1px dashed $colors-green2;
    border-top: 0px dashed $colors-green2;
    border-bottom: 0px dashed $colors-green2;
  }
}

.sponsorsTitle {
  font-weight: bold;
}

.sponsors {
  line-height: 18px;
  display: flex;
  flex-direction: column;
}

.billIcons {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;

  @include bp-min-md {
    flex-direction: column;
    align-items: flex-start;
    max-height: 230px;
    min-width: 135px;
    width: 150px;
  }
}

.billIconContainer {
  line-height: 18px;
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.iconText {
  text-transform: capitalize;
}

.policyText {
  padding-bottom: 0.7rem;
}

.billIcon {
  width: 27px;
}

.billTrack {
  font: inherit;
  line-height: 17px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: $colors-white;
  background: linear-gradient(#61a739 30%, #076324);
  border-radius: 0 0 10px 10px;
  border: 0px;
  cursor: pointer;

  &:hover {
    color: $colors-black;
    background: $colors-green6;

    .closeIcon {
      filter: none;
    }
  }

  &:focus-visible {
    color: $colors-black;
    background: $colors-green6;
    outline: none;

    .closeIcon {
      filter: none;
    }
  }

  @include bp-min-md {
    text-align: center;
    width: 94px;
    flex-direction: column;
    border-radius: 0 10px 10px 0;
  }
}

.greenGrid {
  width: 27px;
}

.removeIcon {
  margin: 0;
}

.billTrackText {
  font-weight: bold;

  @include bp-min-md {
    width: 77px;
  }
}

.closeIcon {
  filter: invert(100%) sepia(0%) saturate(7427%) hue-rotate(11deg) brightness(120%) contrast(120%);
  width: 15.5px;
}
